.wc-container {
    padding: 2em;
    .wc-header {
        display: flex;
        justify-content: space-between;
        align-items: start;
        padding-bottom: 1em;
        border: 0;
        border-bottom: 1px solid rgb(177, 177, 177);
        border-style: dashed;
        margin-bottom: 1.5em;
        .wc-header-type-text {
            display: flex;
            align-items: center;
        }
        .wc-header-time-container{
            .wc-header-time-text{
                margin-bottom: 5px;
            }
        }
    }
    .wc-body {
        .wc-mar-top {
            margin-top: 1.5em;
        }
        .wc-under-flex {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 1em;
        }
        .wc-flex {
            display: flex;
            justify-content: space-between;
            align-items: stretch;
            width: 100%;
            margin-bottom: 1em;
            .wc-data-box {
                width: 49%;
                display: flex;
                justify-content: space-between;
                align-items: baseline;
            }
            .wc-data-divider {
                border: 0.5px solid rgb(177, 177, 177);
                border-style: dashed;
            }
        }
        .wc-total-container {
            margin-top: 3em;
            display: flex;
            justify-content: end;
            .wc-total-box {
                display: flex;
                flex-direction: column;
                align-items: start;
                width: 100%;
                .wc-total-header {
                    margin-bottom: 0.5em;
                }
                .wc-total-amount {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                }
                .wc-line {
                    height: 2px;
                    width: 100%;
                    color: black !important;
                }
                .wc-btn {
                    width: 49%;
                    margin-right: 0.5%;
                }
                .wc-btn-l {
                    width: 49%;
                    margin-left: 0.5%;
                }
                .wc-full-btn {
                    width: 100%;
                    color: black;
                }
            }
        }
    }
}